import request from "../utils/request";

// 省公司数据

// 基础数据
export const getBasicData = data => {
  return request({
    method: "get",
    url: "/big/companyData/baseData",
    data
  })
}

// 商户信息
export const getMerchantInformation = data => {
  return request({
    method: "get",
    url: "/big/companyData/storeData",
    data
  })
}

// 服务团队
export const getServiceTeam = data => {
  return request({
    method: "get",
    url: "/big/companyData/serveData",
    data
  })
}

// 销售榜
export const getSalesChart = data => {
  return request({
    method: 'get',
    url: '/big/companyData/orderData',
    data
  })
}

// 新增用户 
export const getNewOrders = (role) => {
  return request({
    method: "get",
    url: "/big/platformData/newUser",
    data: {
      role,
    }
  })
}


// 总公司数据

// 基础数据

export const getformData = () => {
  return request({
    method: "get",
    url: "/big/platformData/baseData",
  })
}

// 商户信息

export const getstoreData = () => {
  return request({
    method: "get",
    url: "/big/platformData/storeData",
  })
}


// 服务团队
export const getserveData = () => {
  return request({
    method: "get",
    url: "/big/platformData/serveData",
  })
}

// 商城数据

export const getrderData = () => {
  return request({
    method: "get",
    url: "/big/platformData/orderData",
  })
}

// 省公司地图数据
export const getChinaMapData = (id) => {
  return request({
    method: "get",
    url: "/big/platformData/mapData?provinceId=" + id,
  })
}

// 分公司地图数据
export const getProvMapData = (id) => {
  console.log(id);
  return request({
    method: "get",
    url: "/big/companyData/mapData?provinceId=" + id,
  })
}

