import axios from "axios";
// import { getUserTokenConfig } from "../services/login.js"
// 在此可判断当前是什么环境 开发环境、生产环境
// const isDev = process.env.NODE_ENV;
// 基础配置
const ins = axios.create({
  // 根路径 根据上方判断的环境 切换不能请求根地址
  // baseURL: isDev ? 'http://121.89.205.189/admin' : 'http://121.89.205.189/admin',
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  // 设置请求超时的时长
  timeout: 6000,
});

// 请求拦截
ins.interceptors.request.use(
  (config) => {
    // 例如在请求头中设置新属性token，参数为我们的token ，实现每次请求都会在请求头中携带token
    // config.headers['Authorization'] = token;
    // config.headers.common.token = localStorage.getItem('token') || '';
    const token = localStorage.getItem("Access-Token");
    config.headers["Authorization"] = token;

    // if (token && config.url ==='/auth/user/info') {
      
    //   config.headers["Authorization"] = token;
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
ins.interceptors.response.use(

  (response) => {
    // 根据后端返回的数据，错误时的统一操作
    return response;
  },
  (error) => {
    // if(error.response.status === 401) {
    //   getUserTokenConfig().then(() => {
    //     location.reload()
    //   })
    // }
    // return Promise.reject(error);
    return error
  }
);

async function request(config) {
  let { url, data, method = "GET" } = config;

  // restful api GET / POST /PUT/PATCH/DELETE
  const requestIns = () => {
    switch (method.toUpperCase()) {
      case "GET":
        return ins.get(url, { params: data });
      case "POST": // 默认 application/json
        return ins.post(url, data);
      case "PUT": // 全部更新
        return ins.put(url, data);
      case "PATCH": // 局部更新
        return ins.patch(url, data);
      case "DELETE": // 删除
        return ins.delete(url, { data });
      default:
        return ins(config);
    }
  };

  try {
    const { data } = await requestIns();
    // const data = await requestIns();
    if (data.code !== 200) {
      throw Error(data.message);
    }

    return data.data || data;
  } catch (error) {
    // 统一在此处抛出错误处理
    // message.error(error.message);
    return false;
  }
}

export default request;
