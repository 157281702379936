import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () =>
      import( /* webpackChunkName: "index" */ '@/views/HeadOffice/index.vue')
  },
  {
    path: '/province',
    name: 'province',
    component: () =>
      import( /* webpackChunkName: "index" */ '@/views/Homepage/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫
// router.beforeEach((to, from, next) => {
//   if(to !== '/404') {

//     return false
//   }
  
//   next()
// })

export default router
