import Vue from 'vue'
import Vuex from 'vuex'
import { getChinaMapData, getProvMapData } from "@/services/basicdata";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    resData: {},//全国数据
    provResData: {}//省份数据
  },
  mutations: {
    setData(state, data) {
      state.resData = data
    },
    setProvData(state, data) {
      state.provResData = data
    },
  },
  actions: {
    // 获取全国数据
    getMapData({ commit }, id = '') {
      let provinceId = ''
      if (id) {
        provinceId = id + '0000'
      }
      getChinaMapData(provinceId).then((res) => {
        if (res) {
          let mapData = res
          mapData.cityData = res.cityData.filter(item => item.isShow === 0)
          // 将数据保存到store中
          commit('setData', mapData);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    // 获取指定省份数据
    getProvMapData({ commit }, id) {
      let provinceId = ''
      if (id) {
        provinceId = id + '0000'
      }
      getProvMapData(provinceId).then((res) => {
        if (res) {
          // 将数据保存到store中
          commit('setProvData', res);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  modules: {
  }
})
