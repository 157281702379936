import request from "../utils/request";

export const getUserTokenConfig = async () => {
  // const  defaultParams =JSON.parse(window.sessionStorage.getItem('defaultParams'rerree))
  const res = await request({
    url: "/auth/login",
    method: "post",
    data: {
      username:'admin',
      password:'admin321'
    }
  });
  if(res)localStorage.setItem('Access-Token', res);
}

export const getUseInfo = async () => {
  // const  defaultParams =JSON.parse(window.sessionStorage.getItem('defaultParams'rerree))
  const res = await request({
    url: "/auth/user/info",
    method: "get",
  });
  console.log('res', res)
  if(res){
    sessionStorage.setItem('userInfo', JSON.stringify(res));
  } else {
    // 监测后台是否登录
    // location.replace('http://39.105.29.100:30095/user/login');
    location.replace('https://lljm.lilongji.cn')
  }

}

