<template>
  <div id="app">
    <div
      class="big-title"
      title="点击返回礼隆吉后台管理系统"
      @click="handleGoLlgj"
    ></div>
    <div
      v-show="!isFullScreen"
      class="start-full-screen full-screen"
      title="点击显示全屏或按F11显示全屏"
      @click="startFullScreen"
    >
      <!-- <span><img src="@/assets/start_full_screen.jpg" /></span> -->
      <span><img src="@/assets/full_screen.png" /></span>
      <span>全屏</span>
    </div>
    <div id="nav">
      <router-view></router-view>
    </div>
    <div
      v-show="isFullScreen"
      class="end-full-screen full-screen"
      title="点击退出全屏或按F11、ESC退出全屏"
      @click="endFullScreen"
    >
      <span><img src="@/assets/full_screen.png" /></span>
      <span>退出全屏</span>
    </div>
  </div>
</template>
<script>
// import Homepage from "./views/Homepage";
// import HeadOffice from "./views/HeadOffice";
// import qs from "qs";
import { getUseInfo } from './services/login.js'
import { startFullScreen, endFullScreen, getIsFullScreen } from '@/utils/util'

export default {
  components: {
    // Homepage,
    // HeadOffice,
  },
  data() {
    return {
      isFullScreen: false
    }
  },
  created() {
    // this.demo();
    this.setToken()
  },
  mounted() {
    getUseInfo()
    this.isFullScreen = getIsFullScreen()
    window.addEventListener('resize', () => {
      if (window.innerWidth === 1920 && window.innerHeight === 1080) this.isFullScreen = true
      else this.isFullScreen = false
    })
  },
  methods: {
    startFullScreen,
    endFullScreen,
    setToken() {
      const urlParams = Object.fromEntries(new URLSearchParams(window.location.search))
      console.log('urlParams', urlParams)
      if (urlParams.key) {
        localStorage.setItem('Access-Token', urlParams.key)
        localStorage.setItem('urlParams', JSON.stringify(urlParams))
      }
    },
    // async demo() {
    //   const data = window.location.search.split("?")[1] || "";
    //   const token = qs.parse(token).token;
    // const token = this.$route.query.xxx
    //   try {
    //     const res = await login(token);
    //     if (res.status !== 0) {
    //       //无权限
    //       // 第一种
    //       // this.$router.push("/404");
    //       // 第二种
    //       // window.location.href = 'hhtp://www.baidu.com/login'
    //       sessionStorage.setItem('auth',false)
    //     }else{
    //       // this.$router.push("/xxx");
    //     }
    //   } catch (error) {}
    // },
    handleGoLlgj() {
      // window.location.href = 'http://39.105.29.100:30095'
      window.location.href = 'https://lljm.lilongji.cn'
    }
  }
}
</script>
<style lang="less">
#app {
  height: 100%;

  .big-title {
    width: 20%;
    height: 6%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999;
    // background-color: #f00;
    cursor: pointer;
  }

  .full-screen {
    display: flex;
    align-items: center;
    position: fixed;
    right: 2%;
    z-index: 99999999;
    color: #fff;
    cursor: pointer;

    span:first-child {
      margin-right: 4px;
      border: 2px solid #fff;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .start-full-screen {
    top: 10.1%;
  }

  .end-full-screen {
    top: 8.5%;
  }
}
</style>
