// 开始全屏显示
export const startFullScreen = () => {
  let el = document.documentElement;
  // 不同浏览器兼容方法调用
  if (el.requestFullScreen) {
    el.requestFullScreen();
  } else if (el.webkitRequestFullScreen) {
    el.webkitRequestFullScreen();
  } else if (el.mozRequestFullScreen) {
    el.mozRequestFullScreen();
  } else if (el.msRequestFullScreen) {
    el.msRequestFullScreen();
  }

}

// 退出全屏显示
export const endFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msCancelFullScreen) {
    document.msCancelFullScreen();
  }
}

// 获取当前是否全屏状态
export const getIsFullScreen = () => {
  if (document.fullscreenElement || document.webkitFullScreenElement || document.mozFullScreenElement || document.msFullScreenElement) {
    // alert('当前是全屏')
    return true
  } else {
    // alert('当前非全屏')
    return false
  }
}